<template>
    <div class="user-item-wrap">
        <div class="user-item-inner">
            <div class="user-header">
                <div class="title">Traingo服务条款</div>
                <div class="date">2021年10月31日</div>
            </div>
            <div class="user-item-content">
                <p>欢迎阅读《Traingo服务条款》(以下简称“本协议”)。本协议阐述之条款和条件适用于您（以下简称“用户”）使用APP的各种产品和服务。</p>
                <p><span class="pt">1. 服务协议的确认</span></p>
                <p>1.1 上海昶戈信息科技有限公司（以下简称“昶戈公司”）同意按照本协议的规定及其不时发布的操作规则提供基于互联网移动网的昶戈微学APP、昶戈官方网站（www.traingo.cn）等相关服务（以下简称“昶戈服务”）。</p>
                <p>1.2 昶戈服务使用人（以下简称“用户”）应当基于了解本协议全部内容、在独立思考的基础上认可、同意本协议的全部条款并按照页面上的提示完成全部的注册程序，用户的登录、使用等行为将视为完全接受本协议及昶戈公司公示的各项规则、规范。</p>
                <p>1.3 昶戈公司享有对昶戈APP、昶戈官方网站上一切活动的监督、提示、检查、纠正等权利。</p>
                <p><span class="pt">2. 服务内容</span></p>
                <p>2.1 昶戈服务的具体内容由昶戈公司根据实际情况提供，包括但不限于授权用户通过其帐号上传、搜索并向互相关联的用户推送相关信息、授权用户对其进行收录、分享等，昶戈公司有权对其提供的服务或产品形态进行升级或其他调整，均以APP\网站内容更新的方式通知用户，不单独通知用户。</p>
                <p>2.2 昶戈公司提供的昶戈服务中可能包括广告，用户同意在使用过程中显示昶戈和第三方供应商、合作伙伴提供的广告。</p>
                <p>2.3 昶戈公司仅提供与昶戈服务相关的技术服务等，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需费用（如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由用户自行负担。</p>
                <p><span class="pt">3. 服务变更、中断或终止</span></p>
                <p>3.1 鉴于网络服务的特殊性（包括但不限于不可抗力、网络稳定性、系统稳定性、技术故障、用户所在位置、用户关机、用户手机病毒或恶意的网络攻击行为及其他任何技术、互联网络、通信线路、内容侵权等原因），用户同意，昶戈公司有权随时中断或终止部分或全部昶戈服务，昶戈公司将尽可能及时以合理方式通知用户，并尽可能在第一时间对此进行修复。但对因此导致用户不能发送和接受阅读信息、或接发错信息，昶戈公司不承担任何责任，用户须承担以上风险。</p>
                <p>3.2 用户理解，昶戈公司需要定期或不定期地对提供昶戈服务的平台或相关设备进行检修和维护，如因此类情况而造成服务在合理期间内的中断，昶戈公司将尽可能事先通知，但无需为此承担任何责任。</p>
                <p>3.3 用户提供的个人资料不真实、用户违反法律法规国家政策或本协议规定的使用规则，昶戈公司有权随时中断或终止向用户提供本协议项下的昶戈服务，而无需对用户或任何第三方承担任何责任。</p>
                <p><span class="pt">4. 使用规则</span></p>
                <p>4.1 用户账号只能由管理员提供，用户无法自己注册，用户可以向管理员提供准确的主体资料（包括姓名、账号、密码等；），如主体资料有任何变动，必须及时更新。因用户提供资料的真实性问题导致协议双方或第三方的任何损失均由用户承担。</p>
                <p>4.2 管理员提供给用户一个昶戈帐号和密码后，该用户可登录进去更改密码，帐号和密码由用户负责保管；用户应当对该用户帐号所有行为负相关法律责任。</p>
                <p>用户在使用昶戈服务过程中，必须遵循以下原则:</p>
                <p>(1) 遵守中国有关的法律和法规；</p>
                <p>(2) 不得为任何非法目的而使用昶戈服务；</p>
                <p>(3) 遵守所有与网络服务有关的网络协议、规定和程序；</p>
                <p>(4) 不得利用昶戈服务系统进行任何可能对互联网的正常运转造成不利影响的行为；</p>
                <p>(5) 不得利用昶戈网络服务系统进行任何不利于昶戈公司的行为。</p>
                <p>4.3 用户不得使用昶戈服务制作、上传、发送、传播敏感信息和违反国家法律制度的信息，包括但不限于下列信息:</p>
                <p>(1) 反对宪法所确定的基本原则的；</p>
                <p>(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
                <p>(3) 损害国家荣誉和利益的；</p>
                <p>(4) 煽动民族仇恨、民族歧视，破坏民族团结的；</p>
                <p>(5) 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
                <p>(6) 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
                <p>(7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
                <p>(8) 侮辱或者诽谤他人，侵害他人合法权益的；</p>
                <p>(9) 含有法律、行政法规禁止的其他内容的。</p>
                <p>4.4 用户同意在任何情况下不使用其他用户的帐号或密码。在您怀疑他人在使用您的帐号或密码时，您同意立即通知昶戈公司。</p>
                <p>4.5 用户有权更改、删除在昶戈服务中的个人资料、注册信息及传送内容等，但删除有关信息的同时也会删除任何您储存在系统中的文字和图片。用户需承担该风险。</p>
                <p>4.6 如因用户违反本协议中的任何条款，昶戈公司有权依据本协议终止对违约用户昶戈帐号提供服务。</p>
                <p>4.7 如用户注册昶戈帐号后长期不登录该帐号，昶戈公司有权回收该帐号，以免造成资源浪费，由此带来问题均由用户自行承担。</p>
                <p><span class="pt">5. 知识产权</span></p>
                <p>5.1 昶戈公司提供昶戈服务中所包含的任何文本、图片、标识、音频、视频资料均受著作权、商标权、专利权及其他财产权法律的保护。</p>
                <p>5.2 未经相关权利人和昶戈公司的同意，上述资料均不得以任何方式被展示于其他任何第三方平台或被用于其他任何商业目的；用户不得擅自复制、修改、编纂上述内容、或创造与内容有关的衍生产品。</p>
                <p><span class="pt">6. 隐私保护</span></p>
                <p>6.1 本协议所指的“隐私”包括《电信和互联网用户个人信息保护规定》第4条规定的用户个人信息的内容以及未来不时制定或修订的法律法规中明确规定的隐私应包括的内容。</p>
                <p>6.2 保护用户隐私和个人数据是昶戈公司的一项基本制度，昶戈公司将采取各种制度、安全技术和程序等措施来保护用户隐私和个人数据不被未经授权的访问、使用或泄漏，并保证不会将单个用户的注册资料及用户在使用昶戈服务时存储在昶戈公司的非公开内容向除合作单位以外的第三方公开或用于任何非法的用途，但下列情况除外：</p>
                <p>(1) 事先获得用户的明确授权；</p>
                <p>(2) 根据有关法律法规的要求；</p>
                <p>(3) 按照相关政府主管部门的要求；</p>
                <p>(4) 为维护社会公众的利益；</p>
                <p>(5) 用户侵害本协议项下昶戈公司的合法权益的情况下而为维护昶戈公司的合法权益所必须。</p>
                <p>6.3  部分功能需要用户授权使用用户的相机或相册等；若用户不授权，则无法使用本服务或在使用过程中受到限制。用户授权提供的信息，昶戈公司承诺将采取措施保护用户的个人信息安全。</p>
                <p>6.4  为了向用户提供更好的用户体验和提高昶戈APP的服务质量，昶戈公司将可能会收集使用或向第三方提供用户的非个人隐私信息。昶戈公司将对该第三方使用用户个人数据的行为进行监督和管理，尽一切可能努力保护用户个人信息的安全。</p>
                <p><span class="pt">7. 免责声明</span></p>
                <p>7.1 用户在使用昶戈服务的过程中应遵守国家法律法规及政策规定，对其所制作、上传、发送、传播的信息和内容承担任何法律责任，与昶戈公司无关。</p>
                <p>7.2 昶戈APP在其页面上向用户显示、推送的任何信息和内容如系昶戈公司利用技术手段根据用户指令从互联网任何第三方网站搜索、定位、匹配后推荐给用户而非昶戈公司单方制作的，则该显示、推送的信息和内容并不代表昶戈公司及昶戈APP的观点，昶戈公司并不对上述信息的准确性和正确性负责。</p>
                <p>7.3 昶戈公司提供昶戈APP服务中所包含的任何文本、图片、标识、音频、视频资料均为教学用途，不涉及任何实体物品的商业活动。</p>
                <p>7.4 昶戈APP在其页面上向用户显示、推送的任何信息和内容如存在侵权任何第三方知识产权的嫌疑，权利人和相关利害关系人应当向昶戈公司发出权利通知，昶戈公司经过核实后根据有关法律法规有权采取包括但不限于断开该侵权内容的链接或删除并停止传输该侵权内容，但昶戈公司并不对该侵权内容承担法律责任。</p>
                <p>7.5 用户违反本协议的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户同意赔偿昶戈公司，使之免受损害。</p>
                <p><span class="pt">8. 其他条款</span></p>
                <p>8.1 昶戈公司有权随时修改本协议的任何条款，一旦本协议的内容发生变动，昶戈公司将会在昶戈APP平台中公布修改后的协议内容或通过其他适当方式向用户公布修改内容。用户不同意上述修改内容，有权选择停止使用昶戈服务，但用户继续使用，则视为接受昶戈公司对本协议相关条款的修改。</p>
                <p>8.2 本《协议》所定的任何条款无论因何种原因部分或全部无效或不具有执行力，本协议的其余条款仍应有效并具备约束力。</p>
                <p>8.3 本协议的订立、执行和解释及纠纷的解决均应适用中华人民共和国法律并受中华人民共和国法院管辖。如双方就本协议内容或执行发生任何纠纷或争议，首先应友好协商解决，协商不成的，任何一方均可向昶戈公司所在地的有管辖权的人民法院提起诉讼。</p>
                <p>8.4 本协议的版权归昶戈公司所有，本协议各项条款内容的最终解释权及修改权归昶戈公司所有。</p>
                <br/>
                <p>以上声明内容最终解释权在法律允许内归本站所有</p>
            </div>
        </div>
    </div>
</template>

<script>
</script>

<style lang="less" scoped>
@import '../../assets/style/agreement.less';
</style>